<template>
    <div class="out-of-stock-box">
        <p>基本信息</p>
        <div class="arrBox">
            <template v-for="(item, index) in arr1">
                <div class="arr_item">
                    <div>
                        <span>*</span>
                        <span>{{ item.name }}</span>
                    </div>
                    <div>
                        <template v-if="item.type === 'select'">
                            <el-select style="width: 100%;" v-model="formInfo[item.key]"
                                :placeholder="'请选择' + item.name" size="large">
                                <el-option v-for="item1 in item.option" :key="item1.member_id" :label="item1.nickname"
                                    :value="item1.member_id" />
                            </el-select>
                        </template>
                        <template v-else>
                            <el-input v-model="formInfo[item.key]" :placeholder="'请输入' + item.name"></el-input>
                        </template>
                    </div>
                </div>
            </template>
        </div>

        <p>缺货信息</p>
        <div class="arrBox">
            <template v-for="(item, index) in arr2">
                <div :class="['arr_item', item.class]">
                    <div>
                        <span>*</span>
                        <span>{{ item.name }}</span>
                    </div>
                    <div>
                        <template v-if="item.type == 'textarea'">
                            <el-input type="textarea" resize="none" show-word-limit maxlength="200" :rows="4"
                                v-model="formInfo[item.key]" :placeholder="'请输入' + item.name"></el-input>
                        </template>
                        <template v-else>
                            <el-input v-model="formInfo[item.key]" :placeholder="'请输入' + item.name"></el-input>
                        </template>

                    </div>
                </div>
            </template>
        </div>
        <div class="footer">
            <el-button type="primary" @click="sumit">提交</el-button>
        </div>
    </div>
</template>

<script>
import { getMemberList } from "@/api/member/member";
import { stockoutLog } from "@/api/goods/goods";
import { mapGetters } from "vuex"

export default {
    data() {
        return {
            formInfo: {
                goods_name: "",
                specs: "",
                manufactor: "",
                stockout_num: "",
                reason: "",
                member_id: ""
            },
            arr1: [
                { name: '商品名称', key: 'goods_name' },
                { name: '商品规格', key: 'specs' },
                { name: '生产厂家', key: 'manufactor' }
            ],
            arr2: [
                { name: '缺货数量', key: 'stockout_num' },
                { name: '缺货原因', key: 'reason', type: 'textarea', class: 'item_column' }
            ]
        }
    },
    computed: {
        ...mapGetters(["member"])
    },
    mounted() {
        this.formInfo.member_id = this.member.member_id;
        if (this.formInfo.member_id === undefined) {
            setTimeout(() => {
                this.formInfo.member_id = this.member.member_id;
            }, 1000);
        }
        if (this.member.salesman_id) {
            this.getMemberList()
        }
    },
    methods: {
        getMemberList() {
            getMemberList().then(res => {
                if (res.code >= 0) {
                    if (res.data.data.length > 0) {
                        this.arr1.unshift({ name: '缺货客户', key: 'memberId', type: 'select', option: res.data.data })
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        verify() {
            let newArr = this.arr1.concat(this.arr2);
            for (let item in this.formInfo) {
                if (!this.formInfo[item]) {
                    let findObj = newArr.find(item1 => item1.key === item);
                    if (findObj) {
                        this.$message.error('请填写' + findObj.name);
                        return false
                    }
                }
            }
            return true
        },
        clear() {
            this.formInfo.goods_name = "";
            this.formInfo.specs = "";
            this.formInfo.manufactor = "";
            this.formInfo.stockout_num = "";
            this.formInfo.reason = "";
        },
        sumit() {
            if (this.verify()) {
                let newObj = JSON.parse(JSON.stringify(this.formInfo));
                newObj.salesman_id = (this.member.salesman_id) ? this.member.salesman_id : '';
                stockoutLog(newObj).then(res => {
                    if (res.code >= 0) {
                        this.$message.success('提交成功')
                        this.clear();
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.out-of-stock-box {
    display: flex;
    flex-direction: column;
    gap: 12px;

    >p {
        font-weight: 400;
        font-size: 16px;
        color: #3D3D3D;
        line-height: 23px;
        padding-left: 37px;
    }
}

.arrBox {
    display: flex;
    flex-direction: column;
    gap: 26px;
    background: #FFFFFF;
    border-radius: 9px;
    padding: 20px 24px;
    box-sizing: border-box;

    .arr_item {
        display: flex;
        align-items: center;
        gap: 41px;

        >div:nth-child(1) {
            display: flex;
            align-items: center;

            >span:nth-child(1) {
                color: #ff547b;
                font-size: 22px;
                position: relative;
                top: 2px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 16px;
                color: #3D3D3D;
                line-height: 23px;
            }
        }

        >div:nth-child(2) {
            width: 518px;
        }
    }

    .item_column {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        >div:nth-child(2) {
            width: 100% !important;
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>